<template>
  <div class="login-wrap">
    <div class="login-main">
      <div class="login-title">
        <img :src="logoUrl" :alt="siteName" />
      </div>
      <div class="logiNForm">
        <NForm
          ref="loginForm"
          size="large"
          label-placement="left"
          :model="loginFormData"
          :rules="loginFormRules"
        >
          <NFormItem path="username">
            <NInput
              v-model:value="loginFormData.username"
              :placeholder="inputNameTipsTxt"
              @keyup.enter="handleSubmit"
            >
              <template #prefix>
                <NIcon size="18px" color="#808695">
                  <PersonOutline />
                </NIcon>
              </template>
            </NInput>
          </NFormItem>
          <NFormItem path="password">
            <NInput
              type="password"
              show-password-on="click"
              v-model:value="loginFormData.password"
              :placeholder="inputPwdTipsTxt"
              @keyup.enter="handleSubmit"
            >
              <template #prefix>
                <NIcon size="18px" color="#808695">
                  <LockClosedOutline />
                </NIcon>
              </template>
            </NInput>
          </NFormItem>
          <NFormItem>
            <NButton
              block
              type="primary"
              size="large"
              :disabled="logining"
              :loading="logining"
              @click="handleSubmit"
            >登录</NButton>
          </NFormItem>
          <NFormItem style="margin-top: -25px;">
            <n-checkbox v-model:checked="recordLogin">记住密码</n-checkbox>
          </NFormItem>
        </NForm>
      </div>
    </div>
  </div>
</template>

<script setup>
  import { ref, reactive } from 'vue';
  import {
    NForm,
    NFormItem,
    NInput,
    NCheckbox,
    NIcon,
    NButton,
    useMessage
  } from 'naive-ui';
  import { PersonOutline, LockClosedOutline } from '@vicons/ionicons5';
  import { useStore } from 'vuex';
  import { useRouter } from 'vue-router';
  import env from '@/settings/env.js';
  import { INDEX_PAGE_PATH } from '@/enumerators/route.js';
  const { siteName, publicPath, resourceVersion } = env;
  
  const logoUrl = `${publicPath}img/common/logo.png?v=${resourceVersion}`;

  const message = useMessage();
  const router = useRouter();

  const loginForm = ref(null);

  const inputNameTipsTxt = '请输入账号';
  const inputPwdTipsTxt = '请输入登录密码';

  const loginFormData = reactive({
    username: '',
    password: ''
  });
  const loginFormRules = reactive({
    username: { required: true, message: inputNameTipsTxt, trigger: 'blur' },
    password: { required: true, message: inputPwdTipsTxt, trigger: 'blur' }
  });

  const logining = ref(false);
  const recordLogin = ref(false);
  AccountData(0)
  const store = useStore();
  const handleSubmit = () => {
    if (logining.value) {
      return;
    }
    loginForm.value.validate(errors => {
      // 记住密码状态
      if (!errors) {
        logining.value = true;
        store.dispatch('permission/login', {
          ...loginFormData,
          recordLogin: recordLogin.value
        }).then(res => {
          message.success('登录成功');
          AccountData(1)
          router.replace(INDEX_PAGE_PATH);
          setTimeout(() => {
            logining.value = false;
          }, 2000);
        }).catch(err => {
          logining.value = false;
        });
      }
    });
  };

  // 是否记住密码 的数据保存与读取
  function AccountData(is){
    let data = JSON.parse(localStorage.getItem('account'))
    // is:0 初始化时  is:1 提交时
    if(is==0){
      let isrecordLogin = eval(localStorage.getItem('recordLogin'))
      recordLogin.value = isrecordLogin
      // 不是第一次登录
      if(data!=null){
        if(isrecordLogin){
          loginFormData.username = data.username
          loginFormData.password = data.password
        }else{
          loginFormData.username = data.username
        }
      }
    }else{
      if(recordLogin.value){
        localStorage.setItem('account',JSON.stringify(loginFormData));
      }else{
        // 提交时 记住密码为false则
        let thzsData = {"username":loginFormData.username,"password":""}
        localStorage.setItem('account',JSON.stringify(thzsData));
      }
      localStorage.setItem('recordLogin',recordLogin.value);
    }
  }
</script>

<style lang="less" scoped>
  .login-wrap {
    padding-top: 20vh;
  }

  .login-main {
    width: 360px;
    margin: 0 auto;
    padding: 30px 70px 20px;
    background-color: #fff;
    border-radius: var(--uu-border-radius);
    border: var(--uu-border);
  }

  .login-title {
    img {
      @logo-width: 140px;
      display: block;
      width: @logo-width;
      height: @logo-width;
      border-radius: 50%;
      margin: 0 auto 25px;
      object-fit: cover;
    }
  }
</style>